<template>
  <div>
    <molecules-custom-dialog
      v-if="showRemove"
      :show="showRemove"
      title="Apakah Anda Yakin?"
      caption="Anda akan menghapus lokasi"
      :items="[
        {
          title: 'Batalkan',
          full: false,
          exec: () => (showRemove = null),
        },
        {
          title: 'Iya Hapus',
          override: 'red--text',
          full: false,
          exec: () => {
            removeLocation(showRemove)
            showRemove = null
          },
        },
      ]"
    />
    <v-form
      @submit.prevent="
        () => {
          loadDataByQuery()
          query.enabled = true
        }
      "
      id="filter"
    >
      <v-row justify="space-between">
        <v-col cols="10" md="auto" class="grow">
          <v-form
            ref="form"
            v-if="!query.enabled"
            @submit.prevent="
              () => {
                loadDataByQuery()
                query.enabled = true
              }
            "
          >
            <atoms-text-field class="rounded-lg accent" noLabel>
              <v-text-field
                v-model.trim="query.term"
                :style="{
                  'box-shadow': 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
                }"
                @click:append="
                  () => {
                    query = {
                      enabled: false,
                      value: '',
                      item: null,
                      term: '',
                    }
                    page = 1
                    loadData()
                  }
                "
                @keydown.enter="
                  () => {
                    loadDataByQuery()
                    page = 1
                    // query.term = '' // commented for preventing pagination problem
                    query.enabled = true
                  }
                "
                placeholder="Ketikkan kata kunci pencarian..."
                appendIcon="mdi-close"
                class="rounded-lg ma-0 pa-0"
                hide-details
                flat
                solo
                dense
              >
                <template v-slot:prepend>
                  <v-combobox
                    class="rounded-lg ma-0 pa-0"
                    placeholder="Query"
                    v-model="queryFilter"
                    :items="queryFilters"
                    item-text="title"
                    item-value="value"
                    hide-details
                    multiple
                    dense
                    solo
                    flat
                    required
                  /> </template></v-text-field
            ></atoms-text-field>
          </v-form>
          <atoms-text-field v-else :label="isQueryArea ? 'Cari Area' : 'Cari Lokasi'" :no-label="true">
            <v-row dense>
              <v-col cols="1" class="">
                <atoms-button
                  icon
                  @click="
                    () => {
                      query = {
                        enabled: false,
                        value: '',
                        item: null,
                        term: '',
                      }
                      queryFilter = null
                      page = 1
                      loadData()
                    }
                  "
                  ><v-icon>mdi-delete</v-icon></atoms-button
                >
              </v-col>
              <v-col cols="11">
                <v-autocomplete
                  class="rounded-lg"
                  v-model="query.item"
                  :items="(isQueryArea ? $store.state.area.data : $store.state.location.data) || []"
                  :placeholder="` ${query.term ? `Pencarian Sekarang : ${query.term}.` : ''} Pilih \`${
                    isQueryArea ? 'Area' : 'Lokasi'
                  }\` (Opsional)`"
                  :menu-props="{ offsetY: true }"
                  :item-text="(obj) => obj.name || obj.title"
                  :item-value="(obj) => obj"
                  hide-details
                  dense
                  solo
                  flat
                />
              </v-col>
            </v-row>
          </atoms-text-field>
        </v-col>
        <v-col cols="2" md="auto" class="shrink order-md-last" align="end">
          <v-menu offset-y class="rounded-xl">
            <template v-slot:activator="{ on, attrs }">
              <v-btn depressed icon v-bind="attrs" v-on="on"><v-icon>mdi-dots-vertical</v-icon></v-btn>
            </template>
            <v-list nav dense elevation="0" class="rounded-lg">
              <v-list-item
                @click="
                  () => {
                    query = {
                      term: '',
                      value: '',
                      item: null,
                      items: null,
                      enabled: false,
                    }
                    isQueryArea = !isQueryArea
                  }
                "
                link
                class="rounded-lg px-5 primary white--text"
              >
                <v-icon left dark>mdi-database-search</v-icon>
                <v-list-item-title class="px-2">Set Query : {{ !isQueryArea ? 'Area' : 'Lokasi' }} </v-list-item-title>
              </v-list-item>
              <v-list-item
                @click="
                  $router.push({
                    path: '/areas/location/submit',
                  })
                "
                link
                class="rounded-lg px-5"
              >
                <v-icon left>mdi-map-marker-plus</v-icon>
                <v-list-item-title class="px-2">Buat Lokasi</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
        <v-col cols="12" md="2" align="end">
          <atoms-button type="submit" style="width: 100%" class="primary lighten-5 primary--text"
            ><v-icon left>mdi-magnify</v-icon>Cari Lokasi</atoms-button
          >
        </v-col>
      </v-row>
    </v-form>

    <v-form @submit.prevent="loadDataByQuery" id="filter" class="mt-5">
      <v-row justify="space-between">
        <v-col cols="12" md="auto">
          <atoms-button
            class="primary"
            style="width: 100%"
            @click="
              () => {
                sort = sort === constant[0] ? constant[1] : constant[0]
                loadDataByQuery()
              }
            "
            ><v-icon left>mdi-filter-variant-{{ sort === constant[0] ? 'minus' : 'plus' }}</v-icon
            >Sort Tanggal Daftar : {{ sort }}</atoms-button
          >
        </v-col>
        <v-col cols="12" md="auto">
          <v-combobox
            class="rounded-lg"
            placeholder="Filters"
            prepend-inner-icon="mdi-filter-variant"
            :items="filters.map((x) => x.title)"
            v-model="filter"
            @change="loadData"
            hide-details
            multiple
            dense
            solo
            flat
            required
            :style="{
              'box-shadow': 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
            }"
        /></v-col>
      </v-row>
    </v-form>

    <div class="mt-5">
      <div v-if="mainLoading">
        <v-skeleton-loader
          v-for="i in 5"
          :key="i"
          type="list-item-avatar"
          class="accent rounded-lg mb-2 pa-2"
          :style="{
            'box-shadow': 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
          }"
        />
      </div>
      <div v-else-if="$store.state.location.data && $store.state.location.data.length > 0">
        <div
          v-for="(x, i) in query.item && !isQueryArea ? [query.item] : $store.state.location.data || []"
          :key="i"
          :class="['accent', 'rounded-lg pa-4 mb-2']"
          :style="{
            'box-shadow': 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
          }"
        >
          <v-row justify="center">
            <v-col>
              <div class="d-flex flex-col align-center justify-space-between">
                <atoms-title :h2="true" :capital="false"
                  >Lokasi {{ x.name || '-' }}
                  <span v-if="x.inactive" :class="[x.inactive ? 'red--text' : 'primary--text']"
                    >({{ x.inactive ? 'Tidak Aktif' : 'Aktif' }})</span
                  ></atoms-title
                >

                <v-menu offset-x class="rounded-xl">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn depressed icon v-bind="attrs" v-on="on"><v-icon>mdi-dots-vertical</v-icon></v-btn>
                  </template>
                  <v-list nav dense elevation="0" class="text-center rounded-lg">
                    <v-list-item
                      @click="
                        ($event) => {
                          const routerMeta = $router.resolve({
                            path: '/areas/location/submit',
                            query: {
                              targetArea: x.areas._id,
                              targetLocation: x._id,
                            },
                          })
                          $window.open(routerMeta.href, '_blank')
                        }
                      "
                      link
                      class="rounded-lg px-4 text-center"
                    >
                      <v-icon>mdi-pencil</v-icon>
                      <v-list-item-title class="px-2">Edit</v-list-item-title>
                    </v-list-item>
                    <!-- <v-list-item
                      v-if="$isRoles(['Super Admin'])"
                      @click="
                        () => {
                          editableId = _id
                          editableName = name
                          showRemove = true
                        }
                      "
                      link
                      class="rounded-lg px-4 text-center"
                    >
                      <v-icon>mdi-delete</v-icon>
                      <v-list-item-title class="px-2">Hapus</v-list-item-title>
                    </v-list-item> -->
                  </v-list>
                </v-menu>
              </div>
              <div>
                <atoms-text class="mb-5">{{ x.forceGeotagging ? 'Pekerja Wajib Absensi di Lokasi' : 'Pekerja Tidak Wajib Absensi di Lokasi' }}</atoms-text>
                <atoms-text
                  ><span class="font-weight-bold">Area: </span>
                  <v-menu
                    v-if="x.areas && x.areas.title"
                    :open-on-hover="!$vuetify.breakpoint.smAndDown"
                    offset-y
                    :min-width="$vuetify.breakpoint.smAndDown ? '250px' : '500px'"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <span v-on="on" v-bind="attrs" class="primary--text text-uppercase text-decoration-underline">{{
                        x.areas.title
                      }}</span>
                    </template>
                    <v-list nav dense class="pa-4 rounded-xl">
                      <v-hover>
                        <v-list-item
                          @click="
                            $router.push({
                              path: '/areas/review',
                              query: {
                                _id: x.areas._id,
                              },
                            })
                          "
                          slot-scope="{ hover: area_profile_hover }"
                          :class="['mb-4 pa-2', area_profile_hover ? 'primary lighten-5' : '']"
                        >
                          <v-list-item-title
                            ><atoms-text class="font-weight-bold">Area {{ x.areas.title || '-' }}</atoms-text>
                            <!-- <atoms-text>{{
                              (x.areas.contactPerson &&
                                x.areas.contactPerson.email) ||
                              "-"
                            }}</atoms-text> -->
                            <atoms-text :span="true" class="primary--text">Klik untuk lihat detail</atoms-text>
                          </v-list-item-title>
                        </v-list-item>
                      </v-hover>
                      <v-divider class="my-1" />
                      <v-list-item
                        @click="
                          () => {
                            isQueryArea = true
                            query.term = x.areas.title
                            query.enabled = true
                            loadDataByQuery()
                          }
                        "
                        class="rounded-lg px-4"
                      >
                        <v-icon left>mdi-arrow-top-right-thin-circle-outline</v-icon>
                        <v-list-item-title class="px-2">Set sebagai query area</v-list-item-title>
                      </v-list-item>
                      <v-list-item class="rounded-lg px-4">
                        <v-icon left>mdi-arrow-top-right-thin-circle-outline</v-icon>
                        <v-list-item-title class="px-2">Buka pada halaman area</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                  <span v-else>-</span>
                </atoms-text>
                <atoms-text
                  ><span class="font-weight-bold">Client: </span>
                  <v-menu
                    v-if="x.client && x.client.name"
                    :open-on-hover="!$vuetify.breakpoint.smAndDown"
                    offset-y
                    :min-width="$vuetify.breakpoint.smAndDown ? '250px' : '500px'"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <span v-on="on" v-bind="attrs" class="primary--text text-uppercase text-decoration-underline">{{
                        x.client.name
                      }}</span>
                    </template>
                    <v-list nav dense class="pa-4 rounded-xl">
                      <v-hover>
                        <v-list-item
                          @click="
                            $router.push({
                              path: '/clients/review',
                              query: {
                                _id: x.client._id,
                              },
                            })
                          "
                          slot-scope="{ hover: client_profile_hover }"
                          :class="['mb-4 pa-2', client_profile_hover ? 'primary lighten-5' : '']"
                        >
                          <v-list-item-avatar
                            color="background"
                            size="50"
                            class="rounded-full"
                            :style="{
                              cursor: 'pointer',
                            }"
                          >
                            <v-img v-if="x.client.logo" :src="x.client.logo" aspect-ratio="1" />
                            <v-icon v-else> mdi-domain </v-icon>
                          </v-list-item-avatar>
                          <v-list-item-title
                            ><atoms-text class="font-weight-bold">{{ x.client.name || 'Client' }}</atoms-text>
                            <atoms-text>{{
                              (x.client.contactPerson && x.client.contactPerson.email) || '-'
                            }}</atoms-text>
                            <atoms-text :span="true" class="primary--text">Klik untuk lihat detail</atoms-text>
                          </v-list-item-title>
                        </v-list-item>
                      </v-hover>
                      <v-divider class="my-1" />
                      <v-list-item
                        class="rounded-lg px-4"
                        @click="
                          () => {
                            queryFilter = [
                              {
                                title: 'Nama Client',
                                value: (term) => ({
                                  'client.name': {
                                    $regex: term,
                                    $options: 'i',
                                  },
                                }),
                              },
                            ]
                            query.term = x.client.name
                            loadDataByQuery()
                            query.enabled = true
                          }
                        "
                      >
                        <v-icon left>mdi-arrow-top-right-thin-circle-outline</v-icon>
                        <v-list-item-title class="px-2">Set sebagai query client</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                  <span v-else>-</span>
                </atoms-text>
                <atoms-text
                  :href="
                    x.coordinate &&
                    x.coordinate.latitude &&
                    x.coordinate.longitude &&
                    `https://maps.google.com/maps?q=${x.coordinate.latitude},${x.coordinate.longitude}&z=15&output=embed`
                  "
                  ><span class="font-weight-bold">Alamat: </span>{{ x.address }}
                  <span v-if="x.coordinate">-{{ x.coordinate }}</span></atoms-text
                >

                <atoms-text
                  ><span class="font-weight-bold">Dibuat:</span>
                  {{ $moment(x._createdDate).format('dddd, DD MMMM YYYY') }}</atoms-text
                >
                <atoms-text
                  ><span class="font-weight-bold">Terakhir diperbarui:</span>
                  {{ $moment(x._updatedDate).format('dddd, DD MMMM YYYY') }}</atoms-text
                >
              </div>
              <div
                v-if="!x.coordinate || !x.coordinate.latitude || !x.coordinate.longitude || !x.address"
                class="orange pa-2 mt-5 rounded-lg white--text"
              >
                <atoms-text>Lokasi perlu untuk diperbarui dan disesuaikan!</atoms-text>
              </div>
            </v-col>
          </v-row>
        </div>
        <v-row justify="center">
          <v-col cols="8">
            <v-container class="max-width">
              <v-pagination
                class="my-4"
                v-model="page"
                @change="loadData"
                :length="$store.state.location.pagesLength"
              ></v-pagination>
            </v-container>
          </v-col>
        </v-row>
      </div>
      <molecules-wrapper v-else class="d-flex justify-center align-center my-8 py-8 outlined">
        <div class="text-center">
          <atoms-image :width="250" src="/assets/missing.svg" />
          <atoms-title :h3="true" class="mt-5">Tidak ada lokasi yang ditemukan!</atoms-title>
        </div>
      </molecules-wrapper>
    </div>

    <v-slide-x-reverse-transition>
      <organism-locations-register
        v-if="showAdd && detailView"
        :area="detailView"
        @closed="
          (e) => {
            detailView = e
            showAdd = false
          }
        "
      />
    </v-slide-x-reverse-transition>
    <v-slide-x-reverse-transition>
      <organism-locations-edit
        v-if="showEdit && detailView"
        :data="showEdit"
        :area="detailView"
        @closed="
          (e) => {
            if (e) {
              detailView = {
                ...detailView,
                locations: e,
              }
            }
            showEdit = null
          }
        "
      />
    </v-slide-x-reverse-transition>
  </div>
</template>
<script>
export default {
  data() {
    return {
      term: '',
      page: 1,
      limit: 10,
      detailView: null,
      showRemove: null,
      showEdit: null,
      showAdd: false,
      mainLoading: false,
      reportLoading: false,

      isQueryArea: false,
      query: {
        term: '',
        item: null,
        items: null,
        enabled: false,
      },
      queryFilter: null,
      queryFilters: [
        {
          title: 'Nama Lokasi',
          value: (term) => ({
            'locations.name': {
              $regex: term,
              $options: 'i',
            },
          }),
        },
        {
          title: 'Alamat Lokasi',
          value: (term) => ({
            'locations.address': {
              $regex: term,
              $options: 'i',
            },
          }),
        },
        {
          title: 'Nama Area',
          value: (term) => ({
            'areas.title': {
              $regex: term,
              $options: 'i',
            },
          }),
        },
        {
          title: 'Nama Client',
          value: (term) => ({
            'client.name': {
              $regex: term,
              $options: 'i',
            },
          }),
        },
      ],

      sort: '',
      constant: ['Desc', 'Asc'],

      filter: null,
      filters: [
        {
          title: 'Aktif',
          value: {
            $or: [
              {
                'locations.inactive': { $ne: true },
              },
              {
                'locations.inactive': { $exists: true },
              },
            ],
          },
        },
        {
          title: 'Tidak Aktif',
          value: {
            'locations.inactive': true,
          },
        },
      ],
    }
  },
  watch: {
    'query.item'() {
      if (this.isQueryArea) {
        this.getLocations()
      }
    },
    page: {
      handler() {
        if (this.query.item) {
          this.query.item = null
        }
        this.getLocations()
        this.$vuetify.goTo(0)
      },
      deep: true,
    },
  },
  async mounted() {
    if (this.$route.query?.direct) {
      const check =
        this.$store.state.area?.data?.find((x) => x._id === this.$route.query?.direct) ||
        (await this.$store.dispatch('area/getAll', { _id: this.$route.query?.direct }).then((x) => x?.result?.[0]))
      this.detailView = check || null
    }
    if (this.$route.query?.edit && this.detailView) {
      this.showEdit = await this.detailView?.locations?.find((x) => x._id === this.$route.query?.edit)
    }
    this.sort = this.constant[0]
    this.filter = [this.filters[0].title]
    this.loadData()
  },
  methods: {
    countInvalidLocations(locations) {
      return locations?.filter((loc) => !loc?.coordinate?.latitude)?.length || 0
    },
    loadDataByQuery() {
      if (this.isQueryArea) {
        this.getArea()
      } else {
        this.getLocations()
      }
    },
    loadData() {
      this.page = 1
      this.getLocations()
    },

    async getArea() {
      this.mainLoading = true
      try {
        await this.$store.dispatch('area/getAll', {
          custom: {
            _id: this.query.item?.title ? this.query.item?._id : undefined,
            pipeline: [
              ...this.$store.state.area.defaultPipeline,
              {
                $match: {
                  $or: [
                    ...(this.queryFilter?.length > 0
                      ? this.queryFilter?.map((x) =>
                          this.queryFilters?.find((y) => y.title === x.title)?.value?.(this.query.term),
                        )
                      : this.queryFilters.map((x) => x?.value?.(this.query.term))),
                  ],
                },
              },
            ],
          },
        })
        this.query.item = this.$store.state.area.data?.[0]

        this.$nextTick(() => {
          this.getLocations()
        }, 1000)
      } catch (error) {
        Promise.reject(error)
      } finally {
        this.mainLoading = false
      }
    },

    async getLocations() {
      this.mainLoading = true
      try {
        await this.$store.dispatch('location/getAll', {
          custom: {
            _id: this.query.item?.name ? this.query.item?._id : undefined,
            areaId: this.query.item?.title ? this.query.item?._id : undefined,
            pipeline: [
              {
                $sort: {
                  _createdDate: this.sort === this.constant[0] ? -1 : 1,
                },
              },
              ...this.$store.state.location.defaultPipeline,
              {
                $match: {
                  ...(this.filter
                    ? Object.assign({}, ...this.filter?.map((x) => this.filters?.find((y) => y.title === x)?.value))
                    : []),
                  $or: [
                    ...(this.queryFilter?.length > 0
                      ? this.queryFilter?.map((x) =>
                          this.queryFilters?.find((y) => y.title === x.title)?.value?.(this.query.term),
                        )
                      : this.queryFilters.map((x) => x?.value?.(this.query.term))),
                  ],
                },
              },
            ],
          },
          page: this.page,
          limit: this.limit,
        })
      } catch (error) {
        Promise.reject(error)
      } finally {
        this.mainLoading = false
      }
    },

    async removeLocation(location) {
      this.$store.commit('SET', {
        loading: true,
      })
      try {
        await this.$store.dispatch('location/remove', location._id).then((e) => {
          if (e) {
            this.$showDialog({
              title: 'Berhasil',
              body: `Lokasi ${location.name} berhasil dihapus!`,
            })
            this.detailView = e
          }
        })
      } catch (error) {
        this.$showDialog({
          title: 'Gagal',
          body: `Terdapat kesalahan ketika melakukan permintaan`,
        })
        Promise.reject(error)
      } finally {
        this.$store.commit('SET', {
          loading: false,
        })
      }
    },

    // getPages(data, page, limit) {
    //   page = this.page
    //   limit = this.limit
    //   const value = data.valueOf()
    //   return value.slice((page - 1) * limit, page * limit)
    // },
    // getLocByTerm(term, data) {
    //   const finder = data.filter((x) =>
    //     new RegExp(term, "i").test(x.name || x.address)
    //   )
    //   return finder
    // },
    // setArea(value) {
    //   const searcher = this.$store.state.area.data.find(
    //     (x) => x._id === value?._id
    //   )

    //   if (value && searcher) {
    //     if (searcher?.locations?.length <= 0) {
    //       this.$showDialog({
    //         title: "Kesalahan",
    //         body: `Lokasi pada area ${value.title} tidak tersedia!`,
    //       })
    //     }
    //     this.term = ""
    //     this.detailView = searcher
    //     this.page = 1
    //   } else {
    //     this.$showDialog({
    //       title: "Kesalahan",
    //       body: `Tidak dapat memuat data`,
    //     })
    //   }
    // },
  },
}
</script>
